$(function() {

    const $window = $(window);
    const winW = $window.width();

    gsap.timeline({
        scrollTrigger: {
            start: "top -900",
            end: 99999,
            toggleClass: {
                className: 'active',
                targets: '.header'
            },
        }
    })
    // gsap.set($('.row_block .header_cate_list').children('.header_cate_li'), { y: 40, opacity: 0 })

    // $('.row_block .header_cate_list').children('.header_cate_li').hover(function() {
    //     $(this).addClass('show');
    //     gsap.to($(this).find('.header_cate_li'), { duration: .15, y: 0, opacity: 1, stagger: { each: 0.08, from: 'center' } })
    // }, function() {
    //     gsap.set($(this).find('.header_cate_li'), { y: 40, opacity: 0 })
    //     $(this).removeClass('show');
    // });

    const nav_li = $('.nav_li, .header_cate_li');
    // nav_li.mouseenter(function() {
    //     nav_li.removeClass('show');
    //     $(this).addClass('show');
    // });
    // $('a').not('.nav_link').mouseenter(function() {
    //     nav_li.removeClass('show');
    // });
    nav_li.focusin(function() {
        $(this).addClass('show');
    });
    nav_li.focusout(function() {
        $(this).removeClass('show');
    });


    const header = $('.header');
    const hamburger = $('.hamburger');
    let o = true;

    if (winW > 950) {
        const headerH = header.outerHeight(true);
        // hamburger.addClass('cross');
        TweenMax.set(header, { y: -headerH, opacity: 0, display: 'none' });
        hamburger.click(function() {
            if (o) {
                TweenMax.to(header, .4, { opacity: 1, y: 0, display: 'block', ease: Quart.easeOut });
                $(this).addClass('cross');
                o = false;
            } else {
                TweenMax.to(header, .4, { opacity: 0, y: -headerH, display: 'none', ease: Quart.easeOut });
                $(this).removeClass('cross');
                o = true;
            }
        });


        gsap.timeline({
                scrollTrigger: {
                    start: "top -200",
                    end: 99999,
                    toggleClass: {
                        className: 'cross',
                        targets: '.hamburger'
                    },
                    toggleActions: "play none none reverse",
                }
            })
            .add(function() {
                if ($('.hamburger').hasClass('cross')) {
                    o = false;
                } else {
                    o = true;
                }
            })
            .to(header, .4, { opacity: 1, y: 0, display: 'block', ease: Quart.easeOut })


    }

    //menu選單 手機板-------------------------------------------
    if (winW <= 950) {

        // $('.nav_hide').children('.subnav_box').remove();

        const nav = $('.nav'),
            nav_link = $('.nav_link'),
            subnav_box = nav.find('.subnav_box'),
            navReset = function() {
                nav_link.attr('o', 1);
                nav.clearQueue();
                subnav_box.slideUp(0);
            };

        TweenMax.set(nav, { opacity: 0, display: 'none' });
        hamburger.click(function() {
            if (o) {
                navReset();
                TweenMax.to(nav, .4, { opacity: 1, display: 'block', ease: Power1.easeOut });
                $(this).addClass('cross');
                o = false;
            } else {
                TweenMax.to(nav, .4, { opacity: 0, display: 'none', ease: Power1.easeOut });
                $(this).removeClass('cross');
                o = true;
            }
        });

        navReset();
        nav_link.click(function(e) {
            var _this = $(this),
                _thisSubnav = _this.siblings('.subnav_box'),
                _o = _this.attr('o');
            if (_thisSubnav.length <= 0) return;
            e.preventDefault();
            if (_o == 1) {
                _thisSubnav.clearQueue();
                _thisSubnav.slideDown();
                _this.attr('o', 0);
            } else {
                _thisSubnav.slideUp();
                _this.attr('o', 1);
            }
        });

        // const header_cate_link = $('.header_cate_link');
        // cate_li_reset();
        // header_cate_link.click(function(e) {
        //     var _this = $(this),
        //         _thisSubnav = _this.siblings('.subnav_box'),
        //         _o = _this.attr('o');
        //     if (_thisSubnav.length <= 0) return;
        //     e.preventDefault();
        //     if (_o == 1) {
        //         cate_li_reset();
        //         _thisSubnav.clearQueue();
        //         _thisSubnav.slideDown();
        //         _this.attr('o', 0);
        //     } else {
        //         _thisSubnav.slideUp();
        //         _this.attr('o', 1);
        //     }
        // });

        // function cate_li_reset() {
        //     header_cate_link.attr('o', 1);
        //     header_cate_link.siblings('.subnav_box').slideUp(0);
        // }
    }
});