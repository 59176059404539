$(function() {
    var trackerDotW, mouseX, mouseY, trackerW, i;
    $("body").bind("mousemove", function(a) {
        mouseX = a.clientX;
        mouseY = a.clientY;
        trackerW = $(".tracker").width();
        TweenMax.to('.tracker', .3, { x: mouseX - trackerW / 2, y: mouseY - trackerW / 2, opacity: 1, repeat: 0, delay: .05 })
    });

    $('.hover').hover(function() {
        i = getRandom(0, 5);
        $(".tracker").addClass('hover');
        $(".tracker").addClass('color_' + i);
    }, function() {
        $(".tracker").removeClass('hover');
        $(".tracker").attr('class', 'tracker');
    });

    let byteArray, range, max_range;

    function getRandom(min, max) {
        // Create byte array and fill with 1 random number
        byteArray = new Uint8Array(1);
        window.crypto.getRandomValues(byteArray);

        range = max - min + 1;
        max_range = 256;
        if (byteArray[0] >= Math.floor(max_range / range) * range)
            return getRandom(min, max);
        return min + (byteArray[0] % range);
    };
});