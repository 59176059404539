export const lazyload = function() {

    $("img.lazy").lazyload({
        event: "sporty",
        effect: "fadeIn",
        threshold: 200,
        failure_limit: 3, //預先往下尋找非有序呈現的圖片10張
        skip_invisible: false //關閉Lazyload預設忽略不可見圖片(display:none)
    });

    var timeout = setTimeout(function() {
        $("img.lazy").trigger("sporty")
    }, 1000);
}