import '../../css/share/common.scss';
import './layout/_header';
import './layout/_sidenav';
import './components/_tracker';
import { winW } from "./components/_globeVar.js";
import { lazyload } from "./components/_lazyload.js";

$(function() {

    let _href;
    $('a[href$="#"]').click(function(e) {
        e.preventDefault();
        _href = $(this).attr('href');
        if (_href == '#AC') {
            $('#AC').focus();
            TweenMax.to('html, body', .7, { scrollTop: $('#AC').offset().top, ease: Quart.easeOut });
        }
    });

    $('.backtotop').click(function() {
        gsap.to('html, body', { duration: .7, scrollTop: 0, ease: 'power4' })
    });

    //--------------------- imagesLoaded ---------------------//
    function removeLoading() {

        $(".loading").fadeOut(function() {
            $(this).remove();
        });
    }

    imagesLoaded("html, body", function() {
        removeLoading();
        lazyload();
    });
});