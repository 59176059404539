export const winW = $(window).width();
export const winH = $(window).height();

export const getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

export const page = location.pathname.split('/').slice(-1)[0];

export function isEmptyObject(obj) {
    for (var key in obj) {
        return false;
    }
    return true;
}
